import type {
  Country
} from 'react-phone-number-input'

export type Store = {
  id: number
  prefix: string
  locale: string
  region_code: string
  labels: {
    region: string
    language: string
  }
  order: number
  callingCode: Country
  slugs: {
    news: string,
    events: string,
    celebrities: string,
    messikatv: string,
    thisism: string,
  },
  isDefault?: boolean
}

// Data from magento BO
export const stores: Store[] = [
  {
    id: 15,
    prefix: 'ch_cn',
    locale: 'zh-CN',
    region_code: 'cn',
    labels: {
      region: 'Messika Chine',
      language: 'CH - Chinois',
    },
    order: 100,
    callingCode: 'CN',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 1,
    prefix: 'fr',
    locale: 'fr-FR',
    region_code: 'eu',
    labels: {
      region: 'Messika Europe',
      language: 'Français Europe',
    },
    order: 10,
    callingCode: 'FR',
    slugs: {
      news: 'actualites',
      events: 'evenements',
      celebrities: 'celebrites',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
    isDefault: true,
  },
  {
    id: 2,
    prefix: 'en',
    locale: 'en-EU',
    region_code: 'eu',
    labels: {
      region: 'Messika Europe',
      language: 'English Europe',
    },
    order: 20,
    callingCode: 'GB',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 13,
    prefix: 'de',
    locale: 'de-DE',
    region_code: 'eu',
    labels: {
      region: 'Messika Europe',
      language: 'Deutch Europe',
    },
    order: 30,
    callingCode: 'DE',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'prominente',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 17,
    prefix: 'es',
    locale: 'es-ES',
    region_code: 'eu',
    labels: {
      region: 'Messika Europe',
      language: 'Espagne Europe',
    },
    order: 40,
    callingCode: 'ES',
    slugs: {
      news: 'news',
      events: 'eventos',
      celebrities: 'celebridades',
      messikatv: 'messikatv',
      thisism: 'todosobrem',
    },
  },
  {
    id: 21,
    prefix: 'it',
    locale: 'it-IT',
    region_code: 'eu',
    labels: {
      region: 'Messika Europe',
      language: 'Italian Europe',
    },
    order: 50,
    callingCode: 'IT',
    slugs: {
      news: 'notizie',
      events: 'eventi',
      celebrities: 'celebrita',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 20,
    prefix: 'hk_en',
    locale: 'en-HK',
    region_code: 'hk',
    labels: {
      region: 'Messika Hong Kong SAR China',
      language: 'EN - Hong Kong',
    },
    order: 110,
    callingCode: 'HK',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 12,
    prefix: 'jp_ja',
    locale: 'ja-JP',
    region_code: 'jp',
    labels: {
      region: 'Messika Japon',
      language: 'JA - Japonais',
    },
    order: 90,
    callingCode: 'JP',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 22,
    prefix: 'kr_kr',
    locale: 'kr-KR',
    region_code: 'kr',
    labels: {
      region: 'Messika Korea',
      language: 'Corée',
    },
    // No set in the BO
    order: 10000,
    callingCode: 'KR',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 23,
    prefix: 'sa_ar',
    locale: 'ar-SA',
    region_code: 'sa',
    labels: {
      region: 'Messika Saudi Arabia',
      language: 'Arabic - SA',
    },
    // No set in the BO
    order: 10100,
    callingCode: 'SA',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 24,
    prefix: 'sa_en',
    locale: 'en-SA',
    region_code: 'sa',
    labels: {
      region: 'Messika Saudi Arabia',
      language: 'English - SA',
    },
    // No set in the BO
    order: 10200,
    callingCode: 'SA',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 19,
    prefix: 'ar_en',
    locale: 'en-AE',
    region_code: 'ae',
    labels: {
      region: 'Messika United Arab Emirates',
      language: 'English - AR',
    },
    order: 160,
    callingCode: 'AE',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 18,
    prefix: 'ar_ar',
    locale: 'ar-AE',
    region_code: 'ae',
    labels: {
      region: 'Messika United Arab Emirates',
      language: 'Arabic - AR',
    },
    order: 170,
    callingCode: 'AE',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 3,
    prefix: 'uk_en',
    locale: 'en-GB',
    region_code: 'gb',
    labels: {
      region: 'Messika United Kingdom',
      language: 'English UK',
    },
    order: 60,
    callingCode: 'GB',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 4,
    prefix: 'us_en',
    locale: 'en-US',
    region_code: 'us',
    labels: {
      region: 'Messika United States',
      language: 'English US',
    },
    order: 50,
    callingCode: 'US',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 5,
    prefix: 'tu_en',
    locale: 'en-TR',
    region_code: 'tr',
    labels: {
      region: 'Messika Turquie',
      language: 'English Turquie',
    },
    order: 130,
    callingCode: 'TR',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 6,
    prefix: 'su_fr',
    locale: 'fr-CH',
    region_code: 'ch',
    labels: {
      region: 'Messika Suisse',
      language: 'Français Suisse',
    },
    order: 70,
    callingCode: 'CH',
    slugs: {
      news: 'actualites',
      events: 'evenements',
      celebrities: 'celebrites',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 8,
    prefix: 'su_en',
    locale: 'en-CH',
    region_code: 'ch',
    labels: {
      region: 'Messika Suisse',
      language: 'English Suisse',
    },
    order: 80,
    callingCode: 'CH',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 7,
    prefix: 'ru_en',
    locale: 'en-RU',
    region_code: 'ru',
    labels: {
      region: 'Messika Russie',
      language: 'English Russie',
    },
    order: 110,
    callingCode: 'RU',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 14,
    prefix: 'ru_ru',
    locale: 'ru-RU',
    region_code: 'ru',
    labels: {
      region: 'Messika Russie',
      language: 'Russe Russie',
    },
    order: 120,
    callingCode: 'RU',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 1,
    prefix: 'ci_fr',
    locale: 'fr-CI',
    region_code: 'ci',
    labels: {
      region: "Messika Côte d'Ivoire",
      language: "Français Côte d'Ivoire",
    },
    order: 140,
    callingCode: 'CI',
    slugs: {
      news: 'actualites',
      events: 'evenements',
      celebrities: 'celebrites',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
  {
    id: 1,
    prefix: 'ci_en',
    locale: 'en-CI',
    region_code: 'ci',
    labels: {
      region: "Messika Côte d'Ivoire",
      language: "English Côte d'Ivoire",
    },
    order: 150,
    callingCode: 'CI',
    slugs: {
      news: 'news',
      events: 'events',
      celebrities: 'celebrities',
      messikatv: 'messikatv',
      thisism: 'thisism',
    },
  },
];

stores.sort((a, b) => {
  if (a.order > b.order) {
    return 1
  } else if (a.order < b.order) {
    return -1
  }
  if (a.region_code > b.region_code) {
    return 1
  } else if (a.region_code < b.region_code) {
    return -1
  }
  if (a.labels.language > b.labels.language) {
    return 1
  }
  return -1
})

export const defaultStore = stores.find((s) => s.isDefault) as Store

export default stores
